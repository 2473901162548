var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "webrtc_face_recognition" }, [
    _c("div", { staticClass: "option" }, [
      _c("div", [
        _c("label", [_vm._v("面板操作：")]),
        _c("button", { on: { click: _vm.fnOpen } }, [
          _vm._v("启动摄像头视频媒体"),
        ]),
        _c("button", { on: { click: _vm.fnClose } }, [
          _vm._v("结束摄像头视频媒体"),
        ]),
        _c("button", { on: { click: _vm.takeOriPhoto } }, [
          _vm._v("拍摄原始图"),
        ]),
        _c("button", { on: { click: _vm.takePhotoFiveSec } }, [
          _vm._v("开始拍照对比"),
        ]),
      ]),
      _c("div", [
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v("前置后置切换（重新启动摄像头）："),
        ]),
        _c("label", [
          _vm._v(" 前置 "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.constraints.video.facingMode,
                expression: "constraints.video.facingMode",
              },
            ],
            attrs: { type: "radio", value: "user" },
            domProps: {
              checked: _vm._q(_vm.constraints.video.facingMode, "user"),
            },
            on: {
              change: function ($event) {
                return _vm.$set(_vm.constraints.video, "facingMode", "user")
              },
            },
          }),
        ]),
        _c("label", [
          _vm._v(" 后置 "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.constraints.video.facingMode,
                expression: "constraints.video.facingMode",
              },
            ],
            attrs: { type: "radio", value: "environment" },
            domProps: {
              checked: _vm._q(_vm.constraints.video.facingMode, "environment"),
            },
            on: {
              change: function ($event) {
                return _vm.$set(
                  _vm.constraints.video,
                  "facingMode",
                  "environment"
                )
              },
            },
          }),
        ]),
      ]),
      _c("div", [
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v("检测识别类型："),
        ]),
        _c("label", [
          _vm._v(" 轮廓检测 "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.detection,
                expression: "detection",
              },
            ],
            attrs: { type: "radio", value: "landmark" },
            domProps: { checked: _vm._q(_vm.detection, "landmark") },
            on: {
              change: function ($event) {
                _vm.detection = "landmark"
              },
            },
          }),
        ]),
        _c("label", [
          _vm._v(" 表情检测 "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.detection,
                expression: "detection",
              },
            ],
            attrs: { type: "radio", value: "expression" },
            domProps: { checked: _vm._q(_vm.detection, "expression") },
            on: {
              change: function ($event) {
                _vm.detection = "expression"
              },
            },
          }),
        ]),
        _c("label", [
          _vm._v(" 年龄性别检测 "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.detection,
                expression: "detection",
              },
            ],
            attrs: { type: "radio", value: "age_gender" },
            domProps: { checked: _vm._q(_vm.detection, "age_gender") },
            on: {
              change: function ($event) {
                _vm.detection = "age_gender"
              },
            },
          }),
        ]),
      ]),
      _c("div", [
        _c("label", [_vm._v("边框Or面部轮廓：")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.withBoxes,
              expression: "withBoxes",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.withBoxes)
              ? _vm._i(_vm.withBoxes, null) > -1
              : _vm.withBoxes,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.withBoxes,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.withBoxes = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.withBoxes = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.withBoxes = $$c
              }
            },
          },
        }),
      ]),
      _c("div", [
        _c("label", [_vm._v("检测人脸：")]),
        _c("label", [
          _vm._v(" 可信单 "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.detectFace,
                expression: "detectFace",
              },
            ],
            attrs: { type: "radio", value: "detectSingleFace" },
            domProps: { checked: _vm._q(_vm.detectFace, "detectSingleFace") },
            on: {
              change: function ($event) {
                _vm.detectFace = "detectSingleFace"
              },
            },
          }),
        ]),
        _c("label", [
          _vm._v(" 模糊多 "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.detectFace,
                expression: "detectFace",
              },
            ],
            attrs: { type: "radio", value: "detectAllFaces" },
            domProps: { checked: _vm._q(_vm.detectFace, "detectAllFaces") },
            on: {
              change: function ($event) {
                _vm.detectFace = "detectAllFaces"
              },
            },
          }),
        ]),
      ]),
      _c("div", [
        _c("label", [_vm._v("选择算法模型")]),
        _c("label", [
          _vm._v(" ssdMobilenetv1 "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nets,
                expression: "nets",
              },
            ],
            attrs: { type: "radio", value: "ssdMobilenetv1" },
            domProps: { checked: _vm._q(_vm.nets, "ssdMobilenetv1") },
            on: {
              change: function ($event) {
                _vm.nets = "ssdMobilenetv1"
              },
            },
          }),
        ]),
        _c("label", [
          _vm._v(" tinyFaceDetector "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nets,
                expression: "nets",
              },
            ],
            attrs: { type: "radio", value: "tinyFaceDetector" },
            domProps: { checked: _vm._q(_vm.nets, "tinyFaceDetector") },
            on: {
              change: function ($event) {
                _vm.nets = "tinyFaceDetector"
              },
            },
          }),
        ]),
        _c("label", [
          _vm._v(" mtcnn "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nets,
                expression: "nets",
              },
            ],
            attrs: { type: "radio", value: "mtcnn" },
            domProps: { checked: _vm._q(_vm.nets, "mtcnn") },
            on: {
              change: function ($event) {
                _vm.nets = "mtcnn"
              },
            },
          }),
        ]),
      ]),
    ]),
    _c("div", [_vm._v("人脸个数： " + _vm._s(_vm.faceNum))]),
    _c("div", [_vm._v("相识度（越小越精确）:" + _vm._s(_vm.distance))]),
    _c("div", [_vm._v("人脸角度：" + _vm._s(_vm.state))]),
    _c("div", { staticClass: "conta" }, [
      _c("div", { staticClass: "see" }, [
        _c("video", {
          attrs: {
            id: "myVideo",
            poster: "https://dummyimage.com/640x480",
            muted: "",
            loop: "",
            playsinline: "",
          },
          domProps: { muted: true },
          on: { loadedmetadata: _vm.fnRun },
        }),
        _c("canvas", { attrs: { id: "myCanvas" } }),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgParent" }, [
      _c("div", { staticClass: "ori" }, [_c("span", [_vm._v("原始图")])]),
      _c("div", { staticClass: "diff" }, [_c("span", [_vm._v("对比图")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }