<!--
 * @Author: Libra
 * @Date: 2023-02-27 13:42:20
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/test/index.vue
-->

<template>
  <div class="webrtc_face_recognition">
    <div class="option">
      <div>
        <label>面板操作：</label>
        <button @click="fnOpen">启动摄像头视频媒体</button>
        <button @click="fnClose">结束摄像头视频媒体</button>
        <button @click="takeOriPhoto">拍摄原始图</button>
        <button @click="takePhotoFiveSec">开始拍照对比</button>
      </div>
      <div>
        <span
style="margin-right: 20px;"
          >前置后置切换（重新启动摄像头）：</span
        >
        <label>
          前置
          <input
            type="radio"
            v-model="constraints.video.facingMode"
            value="user"
          />
        </label>
        <label>
          后置
          <input
            type="radio"
            v-model="constraints.video.facingMode"
            value="environment"
          />
        </label>
      </div>
      <div>
        <span style="margin-right: 20px;">检测识别类型：</span>
        <label>
          轮廓检测
          <input type="radio" v-model="detection" value="landmark" />
        </label>
        <label>
          表情检测
          <input type="radio" v-model="detection" value="expression" />
        </label>
        <label>
          年龄性别检测
          <input type="radio" v-model="detection" value="age_gender" />
        </label>
      </div>
      <div>
        <label>边框Or面部轮廓：</label>
        <input type="checkbox" v-model="withBoxes" />
      </div>
      <div>
        <label>检测人脸：</label>
        <label>
          可信单
          <input type="radio" v-model="detectFace" value="detectSingleFace" />
        </label>
        <label>
          模糊多
          <input type="radio" v-model="detectFace" value="detectAllFaces" />
        </label>
      </div>
      <div>
        <label>选择算法模型</label>
        <label>
          ssdMobilenetv1
          <input type="radio" v-model="nets" value="ssdMobilenetv1" />
        </label>
        <label>
          tinyFaceDetector
          <input type="radio" v-model="nets" value="tinyFaceDetector" />
        </label>
        <label>
          mtcnn
          <input type="radio" v-model="nets" value="mtcnn" />
        </label>
      </div>
    </div>
    <div>人脸个数： {{ faceNum }}</div>

    <div>相识度（越小越精确）:{{ distance }}</div>

    <div>人脸角度：{{ state }}</div>
    <div class="conta">
      <div class="see">
      <video
        id="myVideo"
        poster="https://dummyimage.com/640x480"
        muted
        loop
        playsinline
        @loadedmetadata="fnRun"
      ></video>
      <canvas id="myCanvas" />
    </div>
    <div class="imgParent">
      <div class="ori">
        <span>原始图</span>
      </div>
      <div class="diff">
        <span>对比图</span>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import * as faceapi from 'face-api.js'
export default {
  name: 'WebRTCFaceRecognition',
  data() {
    return {
      nets: 'ssdMobilenetv1', // 模型
      options: null, // 模型参数
      withBoxes: true, // 框or轮廓
      detectFace: 'detectAllFaces', // 单or多人脸
      detection: 'landmark',
      videoEl: null,
      canvasEl: null,
      context: null,
      photoImg: null,
      oriImg: null,
      timeout: 0,
      faceNum: 0,
      distance: null,
      pitchAngle: null,
      state: null,
      // 视频媒体参数配置
      constraints: {
        audio: false,
        video: {
          // ideal（应用最理想的）
          width: {
            min: 320,
            ideal: 640,
            max: 1920
          },
          height: {
            min: 240,
            ideal: 480,
            max: 1080
          },
          // frameRate受限带宽传输时，低帧率可能更适宜
          frameRate: {
            min: 15,
            ideal: 30,
            max: 60
          },
          // 显示模式前置后置
          facingMode: 'environment'
        }
      }
    }
  },
  watch: {
    nets(val) {
      this.nets = val
      this.fnInit()
    },
    detection(val) {
      this.detection = val
      this.videoEl.pause()
      setTimeout(() => {
        this.videoEl.play()
        setTimeout(() => this.fnRun(), 300)
      }, 300)
    }
  },
  mounted() {
    this.fnInit()
  },
  methods: {
    // 初始化模型加载
    async fnInit() {
      await faceapi.nets[this.nets].loadFromUri('/models') // 算法模型
      await faceapi.loadFaceLandmarkModel('/models') // 轮廓模型
      await faceapi.loadFaceExpressionModel('/models') // 表情模型
      await faceapi.loadAgeGenderModel('/models') // 年龄模型
      await faceapi.loadFaceRecognitionModel('/models')
      // 根据算法模型参数识别调整结果
      switch (this.nets) {
        case 'ssdMobilenetv1':
          this.options = new faceapi.SsdMobilenetv1Options({
            minConfidence: 0.5 // 0.1 ~ 0.9
          })
          break
        case 'tinyFaceDetector':
          this.options = new faceapi.TinyFaceDetectorOptions({
            inputSize: 512, // 160 224 320 416 512 608
            scoreThreshold: 0.5 // 0.1 ~ 0.9
          })
          break
        case 'mtcnn':
          this.options = new faceapi.MtcnnOptions({
            minFaceSize: 20, // 0.1 ~ 0.9
            scaleFactor: 0.709 // 0.1 ~ 0.9
          })
          break
      }
      // 节点属性化
      this.videoEl = document.getElementById('myVideo')
      this.canvasEl = document.getElementById('myCanvas')
      this.context = this.canvasEl.getContext('2d')
      this.canvasEl.width = this.videoEl.width
      this.canvasEl.height = this.videoEl.height
    },
    // 人脸面部勘探轮廓识别绘制
    async fnRunFaceLandmark() {
      console.log('RunFaceLandmark')
      if (this.videoEl.paused) return clearTimeout(this.timeout)
      // 识别绘制人脸信息
      const result = await faceapi[this.detectFace](
        this.videoEl,
        this.options
      ).withFaceLandmarks()
      if (result && !this.videoEl.paused) {
        this.faceNum = result.length
        const dims = faceapi.matchDimensions(this.canvasEl, this.videoEl, true)
        const resizeResult = faceapi.resizeResults(result, dims)
        this.withBoxes
          ? faceapi.draw.drawDetections(this.canvasEl, resizeResult)
          : faceapi.draw.drawFaceLandmarks(this.canvasEl, resizeResult)
      } else {
        this.canvasEl
          .getContext('2d')
          .clearRect(0, 0, this.canvasEl.width, this.canvasEl.height)
      }
      this.timeout = setTimeout(() => this.fnRunFaceLandmark())
    },
    // 人脸表情识别绘制
    async fnRunFaceExpression() {
      console.log('RunFaceExpression')
      if (this.videoEl.paused) return clearTimeout(this.timeout)
      // 识别绘制人脸信息
      const result = await faceapi[this.detectFace](this.videoEl, this.options)
        .withFaceLandmarks()
        .withFaceExpressions()
      if (result && !this.videoEl.paused) {
        const dims = faceapi.matchDimensions(this.canvasEl, this.videoEl, true)
        const resizeResult = faceapi.resizeResults(result, dims)
        this.withBoxes
          ? faceapi.draw.drawDetections(this.canvasEl, resizeResult)
          : faceapi.draw.drawFaceLandmarks(this.canvasEl, resizeResult)
        faceapi.draw.drawFaceExpressions(this.canvasEl, resizeResult, 0.05)
      } else {
        this.canvasEl
          .getContext('2d')
          .clearRect(0, 0, this.canvasEl.width, this.canvasEl.height)
      }
      this.timeout = setTimeout(() => this.fnRunFaceExpression())
    },
    // 年龄性别识别绘制
    async fnRunFaceAgeAndGender() {
      console.log('RunFaceAgeAndGender')
      if (this.videoEl.paused) return clearTimeout(this.timeout)
      // 识别绘制人脸信息
      const result = await faceapi[this.detectFace](this.videoEl, this.options)
        .withFaceLandmarks()
        .withAgeAndGender()
      if (result && !this.videoEl.paused) {
        const dims = faceapi.matchDimensions(this.canvasEl, this.videoEl, true)
        const resizeResults = faceapi.resizeResults(result, dims)
        this.withBoxes
          ? faceapi.draw.drawDetections(this.canvasEl, resizeResults)
          : faceapi.draw.drawFaceLandmarks(this.canvasEl, resizeResults)
        if (Array.isArray(resizeResults)) {
          resizeResults.forEach((result) => {
            const { age, gender, genderProbability } = result
            new faceapi.draw.DrawTextField(
              [
                `${Math.round(age, 0)} years`,
                `${gender} (${Math.round(genderProbability)})`
              ],
              result.detection.box.bottomLeft
            ).draw(this.canvasEl)
          })
        } else {
          const { age, gender, genderProbability } = resizeResults
          new faceapi.draw.DrawTextField(
            [
              `${Math.round(age, 0)} years`,
              `${gender} (${Math.round(genderProbability)})`
            ],
            resizeResults.detection.box.bottomLeft
          ).draw(this.canvasEl)
        }
      } else {
        this.canvasEl
          .getContext('2d')
          .clearRect(0, 0, this.canvasEl.width, this.canvasEl.height)
      }
      this.timeout = setTimeout(() => this.fnRunFaceAgeAndGender())
    },
    // 执行检测识别类型
    fnRun() {
      if (this.detection === 'landmark') {
        this.fnRunFaceLandmark()
        return
      }
      if (this.detection === 'expression') {
        this.fnRunFaceExpression()
        return
      }
      if (this.detection === 'age_gender') {
        this.fnRunFaceAgeAndGender()
        return
      }
    },
    // 启动摄像头视频媒体
    fnOpen() {
      if (typeof window.stream === 'object') return
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout)
        navigator.mediaDevices
          .getUserMedia(this.constraints)
          .then((stream) => this.fnSuccess(stream))
          .catch((stream) => this.fnError(stream))
      }, 300)
    },
    // 成功启动视频媒体流
    fnSuccess(stream) {
      window.stream = stream // 使流对浏览器控制台可用
      this.videoEl.srcObject = stream
      this.videoEl.play()
    },
    // 失败启动视频媒体流
    fnError(error) {
      console.log(error)
      alert('视频媒体流获取错误' + error)
    },
    // 结束摄像头视频媒体
    fnClose() {
      this.canvasEl
        .getContext('2d')
        .clearRect(0, 0, this.canvasEl.width, this.canvasEl.height)
      this.videoEl.pause()
      clearTimeout(this.timeout)
      if (typeof window.stream === 'object') {
        window.stream.getTracks().forEach((track) => track.stop())
        window.stream = ''
        this.videoEl.srcObject = null
      }
    },
    // 拍照
    async takePhoto() {
    // 将视频帧绘制到画布上
      this.context.drawImage(this.videoEl, 0, 0, this.canvasEl.width, this.canvasEl.height)
      // 将画布转换为图片URL并创建一个图片元素显示在网页上
      const imgUrl = this.canvasEl.toDataURL()
      this.photoImg = this.photoImg || document.createElement('img')
      this.photoImg.src = imgUrl
      this.photoImg.style.width = '320px'
      this.photoImg.style.height = '240px'
      const parent = document.querySelector('.diff')
      parent.appendChild(this.photoImg)

      this.start()
    },
    takePhotoFiveSec() {
      setInterval(async() => {
        await this.takePhoto()
      }, 5000)
    },
    takeOriPhoto() {
      // 将视频帧绘制到画布上
      this.context.drawImage(this.videoEl, 0, 0, this.canvasEl.width, this.canvasEl.height)
      // 将画布转换为图片URL并创建一个图片元素显示在网页上
      const imgUrl = this.canvasEl.toDataURL()
      this.oriImg = this.oriImg || document.createElement('img')
      this.oriImg.src = imgUrl
      this.oriImg.style.width = '320px'
      this.oriImg.style.height = '240px'
      const parent = document.querySelector('.ori')
      parent.appendChild(this.oriImg)
    },
    async start() {
      // 检测第一张图片中的人脸
      const detection1 = await faceapi.detectSingleFace(this.oriImg).withFaceLandmarks().withFaceDescriptor()
      // 获取第一张图片中的人脸特征向量
      const faceDescriptor1 = detection1.descriptor

      // 检测第二张图片中的人脸
      const detection2 = await faceapi.detectSingleFace(this.photoImg).withFaceLandmarks().withFaceDescriptor().withFaceExpressions()
      // 获取第二张图片中的人脸特征向量
      if (!detection2) return
      const faceDescriptor2 = detection2.descriptor

      // Face is detected
      if (detection2) {
        var eye_right = this.getMeanPosition(detection2.landmarks.getRightEye())
        var eye_left = this.getMeanPosition(detection2.landmarks.getLeftEye())
        var nose = this.getMeanPosition(detection2.landmarks.getNose())
        var mouth = this.getMeanPosition(detection2.landmarks.getMouth())
        var jaw = this.getTop(detection2.landmarks.getJawOutline())

        var rx = (jaw - mouth[1]) / detection2.detection.box.height
        var ry = (eye_left[0] + (eye_right[0] - eye_left[0]) / 2 - nose[0]) /
              detection2.detection.box.width

        console.log(
          detection2.detection.score, // Face detection score
          ry, // Closest to 0 is looking forward
          rx // Closest to 0.5 is looking forward, closest to 0 is looking up
        )
        let state = 'undetected'
        if (detection2.detection.score > 0.3) {
          state = 'front'
          if (rx > 0.2) {
            state = 'top'
          } else {
            if (ry < -0.04) {
              state = 'left'
            }
            if (ry > 0.04) {
              state = 'right'
            }
          }
        }
        this.state = state
      } else {
        // Face was not detected
      }

      // 使用faceMatcher函数计算两张图片中的人脸相似度
      const faceMatcher = new faceapi.FaceMatcher([faceDescriptor1])
      const match = faceMatcher.findBestMatch(faceDescriptor2)
      this.distance = match.distance
    },
    getTop(l) {
      return l
        .map((a) => a.y)
        .reduce((a, b) => Math.min(a, b))
    },
    getMeanPosition(l) {
      return l
        .map((a) => [a.x, a.y])
        .reduce((a, b) => [a[0] + b[0], a[1] + b[1]])
        .map((a) => a / l.length)
    }
  },
  beforeDestroy() {
    this.fnClose()
  }
}
</script>

<style scoped>
.conta {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.webrtc_face_recognition {
  min-width: 1280px;
}
button {
  height: 30px;
  border: 2px #42b983 solid;
  border-radius: 4px;
  background: #42b983;
  color: white;
  margin: 10px;
}
.see {
  position: relative;
}
.see canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.option {
  padding-bottom: 20px;
}
.option div {
  padding: 10px;
  border-bottom: 2px #42b983 solid;
}
.option div label {
  margin-right: 20px;
}
</style>
